import { useGetFeaturedAgentQuery } from "../services/agentEndpoints";
import { useNacLocation } from "./useNacLocation";

/**
 * @description
 * Hook to fetch the featured agent.
 * This consolidates the fetching and, more importatly, the skip logic
 * for the featured agent.
 *
 * @param {Object} servicingInfo - The servicing info object.
 * @param {string} byBrokerAgentID - The broker agent ID.
 *
 * @returns {Object} - The response object with the featured agent data.
 */
export const useFetchFeaturedAgent = ({
    servicingInfo,
    byBrokerAgentID,
    forceSkip = false,
}) => {
    const {
        state: {
            stateId: state_code,
            nicheItemId,
            developmentId,
            listingId,
        } = {},
    } = useNacLocation();

    const { agentID, multiAgent, agentType } = servicingInfo || {};
    const broker_agent_id =
        agentID || (!multiAgent ? byBrokerAgentID : undefined);

    /**
     * The fetching for a featured agent is set up this way to allow for
     * RTK query caching to work at its best.
     * See https://github.com/lakehomesrealty/Node-as-Consumer/wiki/Featured-Agent---Agent-rotation
     */
    return useGetFeaturedAgentQuery(
        {
            listingID: broker_agent_id === undefined ? listingId : undefined,
            niche_item_id:
                broker_agent_id === undefined ? nicheItemId : undefined,
            development_id:
                broker_agent_id === undefined ? developmentId : undefined,
            broker_agent_id,
            agentType,
            state_code:
                multiAgent && !listingId && !broker_agent_id
                    ? state_code
                    : undefined,
        },
        {
            skip:
                ((multiAgent === false || !multiAgent) &&
                    !byBrokerAgentID &&
                    !agentID) ||
                agentType === "ref" ||
                forceSkip,
        }
    );
};
