import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { Button } from "reactstrap";
import { resetSearch } from "../../../reducers/searchReducer";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import Default from "../../features/Default";
import Overview from "../../features/Overview";
import FeatureList from "../../features/FeatureList";
import EditorWrapper from "../../Editor/EditorWrapper";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { useGetNiche } from "../../../hooks/niche";
import Feature from "../../features/Feature";
import { useNacLocation } from "../../../hooks/useNacLocation";
import PlacesFeature from "./PlacesFeature";
import { getConfigItem } from "../../../support/helpers";
import GroupedFeatures from "../../features/GroupedFeatures";

/**
 * The bottom template item for the Niche page.
 */
const Bottom = ({ isDevelopment, isOffmarket }) => {
    const dispatch = useDispatch();
    const serverOrigin =
        useSelector((state) => state.root.serverOrigin) ||
        (typeof window !== "undefined" && window !== null && window.origin);
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const nicheItems = useSelector((state) => state.pages.nicheItems);
    const developments = useSelector((state) => state.pages.developments);
    const offmarket = useSelector((state) => state.pages.offmarket);
    const { execute: getNiche } = useGetNiche();
    const {
        state: locationState,
        state: { stateId, nicheItemId, developmentId, agentId } = {},
    } = useNacLocation();
    const nicheItem = isOffmarket
        ? offmarket?.[nicheItemId]
        : isDevelopment
        ? developments?.[developmentId]
        : nicheItems?.[nicheItemId];
    const {
        overview,
        information,
        restrictions,
        resources,
        fullBio,
        urlPath,
        urlPathOffmarket,
        gISDefault,
        amenities,
        nearby,
        developments: developmentsList,
    } = nicheItem || {};
    const nichetypeSingularLowerCase = nichetypeSingular.toLowerCase();
    const isOffMarket = useSelector((state) => state.search.isOffMarket);
    const overviewData = overview?.overview || overview;
    const informationData = (information?.information || information || []).map(
        (obj) => {
            // If item has a value and no label, set label=value and value=undefined
            if (!obj.hasOwnProperty("label")) {
                return { ...obj, label: obj.value, value: undefined };
            }
            return obj;
        }
    );
    const resourcesData = resources?.resources || resources;
    const restrictionsData = restrictions?.restrictions || restrictions;
    const { latitude, longitude } = gISDefault || {};
    const enableThirdPartyCategories =
        getConfigItem("REACT_APP_ENABLE_THIRD_PARTY_CATEGORIES") === "true";

    const getOffMarket = async () => {
        bulkPageReducer(dispatch, {
            pageType: "nicheItem",
            ...(await getNiche(
                stateId,
                nicheItemId,
                { gispreview: true },
                true,
                agentId
            )),
            mode: "edit",
        });
    };

    return (
        <>
            <Fetching waitUntil={nicheItem}>
                <div
                    className="container-lg mt-3"
                    id="nicheBottom"
                    data-testid="niche-bottom"
                >
                    <div className="row g-0 mt-3">
                        <div className="col g-0 full-bio" id="fullBio">
                            {fullBio?.label && (
                                <h2 className="w-100 fs-3 border-bottom mb-2 .h4">
                                    {fullBio.label}
                                </h2>
                            )}
                            {isOffMarket || isDevelopment ? (
                                fullBio?.value && (
                                    <div className="d-inline-block me-4">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    typeof fullBio.value ===
                                                    "object"
                                                        ? draftToHtml(
                                                              fullBio.value
                                                          )
                                                        : fullBio.value,
                                            }}
                                        />
                                    </div>
                                )
                            ) : (
                                <EditorWrapper
                                    category="NICHE-ITEMS"
                                    item="Long-Bio"
                                    id={nicheItemId}
                                    editorName="RichTextEditor"
                                    defaultContent={fullBio?.value}
                                    publishCallback={async () => {
                                        bulkPageReducer(dispatch, {
                                            pageType: "nicheItem",
                                            ...(await getNiche(
                                                stateId,
                                                nicheItemId,
                                                { gispreview: true },
                                                false,
                                                agentId
                                            )),
                                            mode: "edit",
                                        });
                                        getOffMarket();
                                    }}
                                >
                                    {fullBio?.value && (
                                        <div className="d-inline-block me-4">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        typeof fullBio.value ===
                                                        "object"
                                                            ? draftToHtml(
                                                                  fullBio.value
                                                              )
                                                            : fullBio.value,
                                                }}
                                            />
                                        </div>
                                    )}
                                </EditorWrapper>
                            )}
                        </div>
                    </div>
                    <div className="row g-0 mt-3">
                        <div className="col g-0">
                            <Feature
                                title="Overview"
                                show={
                                    Array.isArray(overviewData) &&
                                    overviewData.length
                                }
                                monitor={true}
                            >
                                {isDevelopment ? (
                                    <Overview data={overviewData} />
                                ) : (
                                    <EditorWrapper
                                        category="NICHE-ITEMS"
                                        item="Overview"
                                        id={nicheItemId}
                                        editorName="OverviewEditor"
                                        defaultContent={overview}
                                        publishCallback={async () => {
                                            bulkPageReducer(dispatch, {
                                                pageType: "nicheItem",
                                                ...(await getNiche(
                                                    stateId,
                                                    nicheItemId,
                                                    { gispreview: true },
                                                    false,
                                                    agentId
                                                )),
                                                mode: "edit",
                                            });
                                        }}
                                    >
                                        <Overview data={overviewData} />
                                    </EditorWrapper>
                                )}
                            </Feature>
                            <Feature
                                title="Information"
                                show={
                                    Array.isArray(informationData) &&
                                    informationData.length
                                }
                                monitor={true}
                            >
                                {isDevelopment ? (
                                    <Default data={informationData} />
                                ) : (
                                    <EditorWrapper
                                        category="NICHE-ITEMS"
                                        item="Information"
                                        id={nicheItemId}
                                        editorName="InformationEditor"
                                        defaultContent={information}
                                        publishCallback={async () => {
                                            bulkPageReducer(dispatch, {
                                                pageType: "nicheItem",
                                                ...(await getNiche(
                                                    stateId,
                                                    nicheItemId,
                                                    { gispreview: true },
                                                    false,
                                                    agentId
                                                )),
                                                mode: "edit",
                                            });
                                        }}
                                    >
                                        <Default data={informationData} />
                                    </EditorWrapper>
                                )}
                            </Feature>
                            <Feature
                                title="Restrictions"
                                show={
                                    Array.isArray(restrictionsData) &&
                                    restrictionsData.length
                                }
                                monitor={true}
                            >
                                {isDevelopment ? (
                                    <FeatureList data={restrictionsData} />
                                ) : (
                                    <EditorWrapper
                                        category="NICHE-ITEMS"
                                        item="Restrictions"
                                        id={nicheItemId}
                                        editorName="RestrictionEditor"
                                        defaultContent={restrictionsData}
                                        publishCallback={async () => {
                                            bulkPageReducer(dispatch, {
                                                pageType: "nicheItem",
                                                ...(await getNiche(
                                                    stateId,
                                                    nicheItemId,
                                                    { gispreview: true },
                                                    false,
                                                    agentId
                                                )),
                                                mode: "edit",
                                            });
                                        }}
                                    >
                                        <FeatureList data={restrictionsData} />
                                    </EditorWrapper>
                                )}
                            </Feature>
                            {enableThirdPartyCategories ? (
                                <>
                                    <PlacesFeature
                                        key={`${nicheItemId}-amenities`}
                                        title="Amenities"
                                        latitude={latitude}
                                        longitude={longitude}
                                    />
                                    <PlacesFeature
                                        key={`${nicheItemId}-nearby`}
                                        title="Nearby"
                                        latitude={latitude}
                                        longitude={longitude}
                                    />
                                </>
                            ) : (
                                <>
                                    <Feature
                                        title="Amenities"
                                        show={
                                            Array.isArray(amenities) &&
                                            amenities.length
                                        }
                                        monitor={true}
                                    >
                                        <GroupedFeatures data={amenities} />
                                    </Feature>
                                    <Feature
                                        title="Nearby"
                                        show={
                                            Array.isArray(nearby) &&
                                            nearby.length
                                        }
                                        monitor={true}
                                    >
                                        <GroupedFeatures data={nearby} />
                                    </Feature>
                                </>
                            )}
                            <Feature
                                title="Resources"
                                show={
                                    Array.isArray(resourcesData) &&
                                    resourcesData.length
                                }
                                monitor={true}
                            >
                                {isDevelopment ? (
                                    <FeatureList data={resourcesData} />
                                ) : (
                                    <EditorWrapper
                                        category="NICHE-ITEMS"
                                        item="Resources"
                                        id={nicheItemId}
                                        editorName="ResourceEditor"
                                        defaultContent={resourcesData}
                                        publishCallback={async () => {
                                            bulkPageReducer(dispatch, {
                                                pageType: "nicheItem",
                                                ...(await getNiche(
                                                    stateId,
                                                    nicheItemId,
                                                    { gispreview: true },
                                                    false,
                                                    agentId
                                                )),
                                                mode: "edit",
                                            });
                                        }}
                                    >
                                        <FeatureList data={resourcesData} />
                                    </EditorWrapper>
                                )}
                            </Feature>
                        </div>
                    </div>
                    {developmentsList && developmentsList.length > 0 && (
                        <div className="row g-0 mt-3">
                            <h2 className="w-100 fs-3 border-bottom mb-2 .h4">
                                Developments
                            </h2>
                            <div className="text-break px-3">
                                <DiItems
                                    items={developmentsList.map((item) => ({
                                        ...item,
                                        more: { ...item.more, stateId },
                                    }))}
                                />
                            </div>
                        </div>
                    )}
                    {!isDevelopment && (
                        <div className="row g-0 mt-3">
                            <div className="col">
                                <Button
                                    color="link"
                                    className="p-0 mb-2"
                                    href={`${serverOrigin}/info/sell-a-${nichetypeSingularLowerCase}-home`}
                                    data-test={`sell-${nichetypeSingularLowerCase}-home`}
                                >
                                    Sell Your {nichetypeSingular} Home
                                </Button>
                                {isOffmarket ? (
                                    <Link
                                        className="d-block mb-2"
                                        to={{
                                            pathname: `/${urlPath}`,
                                            state: locationState,
                                        }}
                                        onClick={() => resetSearch(dispatch)}
                                        data-test="available"
                                    >
                                        Available Properties
                                    </Link>
                                ) : (
                                    <Link
                                        className="d-block mb-2"
                                        to={{
                                            pathname: `/${urlPathOffmarket}`,
                                            state: locationState,
                                        }}
                                        onClick={() => resetSearch(dispatch)}
                                        data-test="offmarket"
                                    >
                                        Off Market Properties
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Fetching>
        </>
    );
};

export default Bottom;
