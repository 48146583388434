import NicheMain from "../Niche/Main";
import NicheBottom from "../Niche/Bottom";
import AgentMain from "../Agent/Main";
import AgentBottom from "../Agent/Bottom";
import StateBottom from "../State/Bottom";
import StateMain from "../State/Main";
import ListingBottom from "../Listing/Bottom";
import ListingTop from "../Listing/Top";
import ListingMain from "../Listing/Main";
import HeaderBar from "../Listing/HeaderBar";

const TemplateItemProvider = ({ children, page, isOffmarket }) => {
    const templateItems = {
        listing: {
            HeaderContent: HeaderBar,
            TopContent: ListingTop,
            BottomContent: ListingBottom,
            MainContent: ListingMain,
            hideHeaderBar: isOffmarket,
        },
        agent: {
            hideHeaderBar: true,
            MainContent: AgentMain,
            BottomContent: AgentBottom,
        },
        development: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        offmarket: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        niche: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        state: {
            MainContent: StateMain,
            BottomContent: StateBottom,
        },
    };

    return children(templateItems[page] || {});
};

export default TemplateItemProvider;
