import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import useToggle from "../../../hooks/useToggle";
import DiItem from "../../DisplayItems/DiItem";
import {
    replaceAllMultiple,
    toLowerAndReplace,
} from "../../../support/helpers";
import { setActiveMarker } from "../../../reducers/mapReducer";
import { useQueryParams } from "use-query-params";
import { searchQueryModel } from "../../Search/SearchProvider";
import { stringify } from "query-string";
import { useSearchData } from "../../../hooks/useSearchData";

const NicheItem = ({ dataItem: item, filterValue }) => {
    const dispatch = useDispatch();
    const { hasSearched } = useSearchData();
    const [isOpen, toggleIsOpen] = useToggle(false);
    const IconName = isOpen ? FaChevronUp : FaChevronDown;
    const hasChildren = Array.isArray(item?.more?.children);
    const { page, ...pageRemovedSearchQueryModel } = searchQueryModel;
    const [searchQuery] = useQueryParams(pageRemovedSearchQueryModel);
    const stringQuery = stringify(searchQuery);
    const lowerAndReplace = (value) =>
        replaceAllMultiple(value.toLowerCase(), { " ": "", "-": "" });

    useEffect(() => {
        if (filterValue && !isOpen) {
            toggleIsOpen(true);
        } else if (!filterValue) {
            toggleIsOpen(false);
        }
        // eslint-disable-next-line
    }, [filterValue]);

    return (
        <div
            className="niche-item border-bottom mb-1 pb-1"
            data-test="niche-item"
            style={{ breakInside: "avoid-column" }}
            onMouseEnter={() => dispatch(setActiveMarker(item?.more?.id))}
            onMouseLeave={() => dispatch(setActiveMarker())}
        >
            <div className="d-flex">
                <DiItem
                    {...item}
                    valueLink={
                        stringQuery
                            ? `${item.valueLink}?${stringQuery}`
                            : item.valueLink
                    }
                    value={
                        hasSearched && item?.more?.stateCode
                            ? `${item?.value}, ${item?.more?.stateCode}`
                            : item.value
                    }
                    type="value"
                    linkClasses="w-100 fw-bold text-truncate text-decoration-none"
                    classes="value text-truncate"
                    noDisplayWrapper={false}
                    isHtml={false}
                    buttonColor="link"
                    listingsClasses={`small ${
                        !stringQuery ? "" : "d-none"
                    } text-black fw-normal`}
                />
                {hasChildren && (
                    <Button
                        color="link"
                        className="ms-auto align-self-end text-primary px-1"
                        onClick={() => toggleIsOpen()}
                    >
                        <IconName />
                    </Button>
                )}
            </div>
            {hasChildren && (
                <div className={isOpen ? "d-block" : "d-none"}>
                    {(item?.more?.children || [])
                        .filter((item) =>
                            filterValue
                                ? lowerAndReplace(item.name).includes(
                                      lowerAndReplace(filterValue)
                                  )
                                : true
                        )
                        .map((child, i) => (
                            <Link
                                key={`child-niche-item-${i}`}
                                to={{
                                    pathname: child.path,
                                    state: { nicheItemId: child.nicheItemID },
                                }}
                                className="text-truncate ms-2 d-block"
                                data-test={`niche-child-${toLowerAndReplace(
                                    child.name
                                )}`}
                            >
                                {child.name} ({child.totalListings})
                            </Link>
                        ))}
                </div>
            )}
        </div>
    );
};

export default NicheItem;
