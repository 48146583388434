import React from "react";
import { useSelector } from "react-redux";
import Fetching from "../../Fetching";
import Breadcrumbs from "../../Breadcrumbs";
import Badges from "../../Badges";
import Contact from "./Contact";
import Header from "./Header";
import ImageTiles from "./ImageTiles";
import Highlights from "./Highlights";
import { useNacLocation } from "../../../hooks/useNacLocation";
import { mapAgentInfo } from "../../FeaturedAgent/hooks/useFeaturedAgent";
import { useFetchFeaturedAgent } from "../../../hooks/useFetchFeaturedAgent";

const Top = () => {
    const listings = useSelector((state) => state.pages.listings);
    const styling = useSelector((state) => state.root.styling);
    const { state: { listingId = undefined } = {} } = useNacLocation();
    const listing = listings?.[listingId];
    const {
        address,
        highlights,
        images,
        headerLinks,
        badges,
        servicingInfo,
        byBrokerAgentID,
    } = listing || {};
    const { agentType } = servicingInfo || {};
    const isArchived = useSelector(
        (state) => state.pages.listings[listingId]?.identifiers?.isArchived
    );
    const isOffmarket = isArchived === 1;

    const { data } = useFetchFeaturedAgent({
        servicingInfo,
        byBrokerAgentID,
        forceSkip: isOffmarket,
    });
    const featuredAgent = mapAgentInfo(
        agentType === "ref" ? servicingInfo : data,
        styling
    );

    return (
        <Fetching waitUntil={listing}>
            <div>
                <div
                    className="container-xxl mt-3 px-3 px-xxl-0 d-none d-md-block"
                    data-testid="listing-top"
                >
                    <div className="row g-0">
                        <div className="d-flex w-100">
                            <Breadcrumbs crumbs={headerLinks} />
                            <Badges
                                className="d-none d-md-flex align-items-center ms-auto"
                                {...badges}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column-reverse d-md-block">
                    <Header className="row g-0 px-3 my-3" {...listing} />
                    <div className="position-relative">
                        <div
                            className="position-absolute d-md-none d-flex w-100"
                            style={{ zIndex: 1030, top: "10px", left: "12px" }}
                        >
                            <Badges {...badges} showLimit={2} />
                        </div>
                        {images && images.length > 0 && (
                            <ImageTiles
                                images={images}
                                titleContent={`${address?.line1}`}
                            />
                        )}
                    </div>
                </div>
                <div className="container-xxl px-3 px-xxl-0">
                    {!isOffmarket && (
                        <div
                            className="d-flex d-md-none justify-content-between"
                            data-test="inline-contact-buttons"
                        >
                            <Contact
                                color="primary"
                                className="inverse me-1 px-0 flex-grow-1"
                                contactType="Showing"
                                header="Schedule a showing"
                                featuredAgent={featuredAgent}
                            >
                                Showing
                            </Contact>
                            <Contact
                                color="primary"
                                className="ms-1 px-0 flex-grow-1"
                                featuredAgent={featuredAgent}
                            >
                                More Info
                            </Contact>
                        </div>
                    )}
                    <div className="row g-0">
                        <Highlights highlights={highlights} />
                    </div>
                </div>
            </div>
        </Fetching>
    );
};

export default Top;
