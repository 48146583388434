import React, { useRef } from "react";
import { useFetchCategory } from "./hooks/useFetchCategory";
import Feature from "../../../features/Feature";
import GroupedFeatures from "../../../features/GroupedFeatures";

const PlacesFeature = ({ title, latitude, longitude }) => {
    const ref = useRef(null);
    const { data = [] } = useFetchCategory(
        ref,
        title.toLowerCase(),
        latitude,
        longitude
    );

    // We only want to show the feature if there are items to display
    const scrubbedData = data.filter((item) => item.items.length > 0);

    return (
        <span ref={ref}>
            <Feature
                title={title}
                show={Array.isArray(scrubbedData) && scrubbedData.length}
                monitor={true}
            >
                <GroupedFeatures data={scrubbedData} />
            </Feature>
        </span>
    );
};

export default PlacesFeature;
