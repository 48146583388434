import { useState, useEffect } from "react";

export default function useOnScreen(ref, options = {}, initialValue = false) {
    const [isIntersecting, setIntersecting] = useState(initialValue);

    let observer;
    if (typeof window !== "undefined" && window !== null) {
        observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            options
        );
    }

    useEffect(() => {
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line
    }, []);

    return isIntersecting;
}
