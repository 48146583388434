import React from "react";
import { AccordionItem, AccordionBody } from "reactstrap";
import StateNicheList from "../../StateNicheList";
import { useSearchData } from "../../../../../hooks/useSearchData";
import NicheItemsTitle from "./NicheItemsTitle";
import StateAccordionHeader from "../StateAccordionHeader";

function AccordionNicheItems({ id, data, isOpen, nicheListOverride }) {
    const { locationData: location, hasSearched } = useSearchData();
    const { nicheItemsPreviewInfo } = data || {};
    const nicheItems =
        hasSearched && location?.matchesNicheItems
            ? (location?.matchesNicheItems?.diNicheItems || []).filter(
                  (item) => !item?.more?.hasChildren
              )
            : nicheItemsPreviewInfo;
    const nicheList = nicheListOverride || nicheItems;

    return (
        <AccordionItem>
            <StateAccordionHeader targetId={id} isOpen={isOpen}>
                <NicheItemsTitle data={data} />
            </StateAccordionHeader>
            <AccordionBody accordionId={id}>
                <StateNicheList nicheList={nicheList} />
            </AccordionBody>
        </AccordionItem>
    );
}

export default AccordionNicheItems;
