import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useGetAgent } from "../../../hooks/agent";
import { getPageType } from "../../../support/helpers";
import { setPageError } from "../../../reducers/rootReducer";
import { setMarkers, setGisDefault } from "../../../reducers/mapReducer";
import {
    setPageHeaderInfo,
    addAgentPage,
    removePage,
} from "../../../reducers/pagesReducer";
import { removeAgent } from "../../../reducers/featuredAgentReducer";
import { useNacLocation } from "../../../hooks/useNacLocation";

const AgentProvider = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const agents = useSelector((state) => state.pages.agents);
    const { state: { agentId: stateAgentId, stateId, nicheItemId } = {} } =
        useNacLocation();
    const agentId =
        stateAgentId ||
        (typeof window !== "undefined" &&
            window !== null &&
            window?.locationState?.agentId);
    const routeParams = useParams();
    const { execute: getAgent, error } = useGetAgent();
    const agent = agents?.[agentId];
    const { gISDefault, pageHeaderInfo } = agent?.data || {};

    useEffect(() => {
        let currentNiche;
        try {
            currentNiche = agent?.data?.headerLinks[2][0]?.more.nicheItemId;
        } catch {}
        if (
            agentId &&
            getPageType(routeParams, "agent", agentId) &&
            currentNiche !== nicheItemId
        ) {
            const fetchData = async () => {
                const response = await getAgent(agentId, stateId, nicheItemId, {
                    gispreview: true,
                });

                // Agent has been deactivated and we are going to redirect them.
                if (
                    response?.statusCode === 400 &&
                    response?.message?.agentId.includes("Inactive")
                ) {
                    dispatch(removePage({ pageType: "brokerAgents" }));
                    dispatch(removeAgent(agentId));
                    history.replace(`/agents`);
                } else {
                    dispatch(addAgentPage({ id: agentId, data: response }));
                }
            };
            fetchData();
        }
        // eslint-disable-next-line
    }, [routeParams, agentId]);

    useEffect(() => {
        if (agent) {
            if (gISDefault) dispatch(setGisDefault(gISDefault));
            const agentListings =
                agent?.gis?.results || agent?.listingsPreview?.results || [];
            dispatch(
                setMarkers(
                    agentListings.map((listing) => ({
                        ...listing,
                        type: "listing",
                        click: {
                            path: listing.urlPath,
                            state: { listingId: listing.listingId },
                        },
                    }))
                )
            );
        }
        // eslint-disable-next-line
    }, [agent, gISDefault]);

    useEffect(() => {
        if (pageHeaderInfo && getPageType(routeParams, "agent", agentId)) {
            dispatch(setPageHeaderInfo(pageHeaderInfo));
        }
    }, [agent, dispatch, routeParams]);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "Agent",
                    error: "Error loading agent page.",
                })
            );
        }
    }, [dispatch, error]);

    return children;
};

export default AgentProvider;
