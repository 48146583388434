import React from "react";
import { AccordionHeader } from "reactstrap";

const StateAccordionHeader = ({ children, isOpen, ...props }) => (
    <AccordionHeader {...props}>
        {children}
        {isOpen && (
            <div className="ms-2">
                <svg height={12} width={12}>
                    <circle cx="50%" cy="50%" r={6} fill="var(--bs-primary)" />
                </svg>
            </div>
        )}
    </AccordionHeader>
);

export default StateAccordionHeader;
