import { useParams } from "react-router-dom";
import { getPageType } from "../../../../../support/helpers";
import { StringParam, useQueryParam } from "use-query-params";
import { useNacLocation } from "../../../../../hooks/useNacLocation";
import { useSelector } from "react-redux";
import { pageTypes } from "../../../../../reducers/pagesReducer";

export const useNicheAreasAndListings = () => {
    const pageType = pageTypes[getPageType(useParams(), false)];
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const { state: { nicheItemId, developmentId } = {} } = useNacLocation();

    const nicheData = useSelector(
        (state) => state.pages.nicheItems?.[nicheItemId]
    );

    const id = pageType === "developments" ? developmentId : nicheItemId;
    const open = mapView || "listings";

    const { childNicheItems, urlPath, listingsPreview } =
        useSelector((state) => state.pages?.[pageType]?.[id]) || {};

    const nicheList = childNicheItems ? childNicheItems.dILinks : [];

    const toggle = (id) => {
        if (id !== open) {
            setMapView(id === "areas" ? "areas" : undefined);
        } else {
            setMapView(id === "areas" ? undefined : "areas");
        }
    };

    return {
        open,
        toggle,
        listingsPreview,
        urlPath,
        nicheData,
        nicheList,
        childNicheItems,
    };
};
