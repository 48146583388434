import { StringParam, useQueryParam } from "use-query-params";
import { getPageType } from "../../../../../support/helpers";
import { pageTypes } from "../../../../../reducers/pagesReducer";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSearchData } from "../../../../../hooks/useSearchData";

export const useSelectionContext = ({ stateId, nicheItemId, agentId }) => {
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const pageType = pageTypes[getPageType(useParams(), false)];
    const nicheData =
        useSelector((state) => state.pages?.[pageType]?.[nicheItemId]) || {};
    const { childNicheItems, listingsPreview } = nicheData;
    const { listingsPreview: agentListings } =
        useSelector((state) => state.pages?.agents?.[agentId]) || {};
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const stateRegions = stateData?.stateRegions || [];
    const isFetchingGis = useSelector((state) => state.search.isFetchingGis);
    const { hasSearched } = useSearchData();
    const hasRegions = stateRegions.length > 0;
    const nichetypePlural =
        useSelector(
            (state) => state.root.styling.nichetypePlural
        ).toLowerCase() || "niches";
    const defaultView = childNicheItems
        ? "listings"
        : hasSearched
        ? "listings"
        : hasRegions
        ? "regions"
        : nichetypePlural;
    const selection = mapView || defaultView;

    return {
        setMapView,
        pageType,
        stateData,
        nicheData,
        childNicheItems,
        listingsPreview,
        agentListings,
        isFetchingGis,
        hasSearched,
        hasRegions,
        nichetypePlural,
        selection,
    };
};
