import React, { useEffect } from "react";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { useSelector } from "react-redux";

/**
 * Cordless Media display ad.
 * https://ads.cordlessmedia.com/documentation/index.html#display.md
 * This is just the html tag portion of the implementation. The JS is added in Google Tag Manager.
 * @param location {string} - The location of the ad. The location is used to determine which ad unit to display.
 */
const DisplayAd = ({ location }) => {
    const { trackGTM } = useTrackEvent();
    const cordlessAds = useSelector(
        (state) => state.root.styling.misc?.cordlessMedia?.ads
    );

    const { id, units } = cordlessAds || {};
    const adId = units?.[location];

    if (!adId) return null;
    useEffect(() => {
        trackGTM({
            event: "adDisplay",
            type: "cordlessMedia",
            location: "Listing Page",
            adId: adId,
            uniqueId: id,
        });
    }, []);

    return <div id={adId}></div>;
};

export default DisplayAd;
