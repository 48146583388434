import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import FeaturedAgent from "../../FeaturedAgent";
import Breadcrumbs from "../../Breadcrumbs";
import OffMarketBanner from "./OffMarketBanner";
import MegaAssociatesFilter from "./MegaAssociatesFilter";
import NicheItemMetrics from "./NicheItemMetrics";
import ShortBio from "./ShortBio";
import NicheAreasAndListings from "./NicheAreasAndListings";
import PageTitle from "../PageTitle";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";
import EditorWrapper from "../../Editor/EditorWrapper";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getDevelopment } from "../../../hooks/useDevelopment";

const Main = ({ isOffmarket, setShouldCluster, isDevelopment }) => {
    const dispatch = useDispatch();
    const [mapView] = useQueryParam("mapView", StringParam);
    const nicheItems = useSelector((state) => state.pages.nicheItems);
    const developments = useSelector((state) => state.pages.developments);
    const offmarket = useSelector((state) => state.pages.offmarket);
    const nichetypePlural = useSelector(
        (state) => state.root.styling.nichetypePlural
    ).toLowerCase();

    const isListingsView =
        mapView !== "areas" &&
        mapView !== "regions" &&
        mapView !== nichetypePlural;

    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const { state: { stateId, nicheItemId, developmentId } = {} } =
        useNacLocation();
    const nicheItem = isOffmarket
        ? offmarket?.[nicheItemId]
        : isDevelopment
        ? developments?.[developmentId]
        : nicheItems?.[nicheItemId];
    const {
        headerLinks,
        displayName,
        stateName,
        developmentDesc,
        childNicheItems,
        servicingInfo,
    } = nicheItem || {};

    useEffect(() => {
        setShouldCluster(isListingsView);
        return () => setShouldCluster(false);
        // eslint-disable-next-line
    }, [isListingsView, childNicheItems]);

    return (
        <Fetching waitUntil={nicheItem}>
            <div className="p-3" data-testid="niche-main">
                <Breadcrumbs className="pt-0" crumbs={headerLinks} />
                <OffMarketBanner isOffmarket={isOffmarket} />
                <PageTitle
                    className="mb-2"
                    data-test="nicheItem-title"
                    title={isDevelopment ? developmentDesc?.label : displayName}
                    subtitle={
                        (isDevelopment ? `${displayName}, ` : "") + stateName
                    }
                />
                <NicheItemMetrics isOffmarket={isOffmarket} />
                <ShortBio />
                {isDevelopment && (
                    <EditorWrapper
                        category="NICHE-ITEMS"
                        item="Development-Description"
                        id={developmentId}
                        editorName="RichTextEditor"
                        defaultContent={developmentDesc?.value}
                        publishCallback={async () => {
                            bulkPageReducer(dispatch, {
                                pageType: "nicheItem_development",
                                ...(await getDevelopment(
                                    sessionID,
                                    stateId,
                                    nicheItemId,
                                    developmentId,
                                    { gispreview: true }
                                )),
                                mode: "edit",
                            });
                        }}
                    >
                        <DiItems
                            items={[developmentDesc]}
                            wrapperClsses="d-inline-block mt-3"
                            labelClasses="d-none"
                            valueIsHtml={true}
                            dataTest="development-description"
                        />
                    </EditorWrapper>
                )}
                <FeaturedAgent
                    className="mt-3 mb-3"
                    dataTest="nicheItem-featured-agent"
                    servicingInfo={servicingInfo}
                />
                {!isOffmarket && <MegaAssociatesFilter />}
                <NicheAreasAndListings isOffmarket={isOffmarket} />
            </div>
        </Fetching>
    );
};
export default Main;
