import { useEffect } from "react";
import useToggle from "../../../hooks/useToggle";
import { useTrackEvent } from "../../../hooks/useTrackEvent";
import { useSelector } from "react-redux";
import { useFetchFeaturedAgent } from "../../../hooks/useFetchFeaturedAgent";

/**
 * @description
 * Hook for fetching and displaying a featured agent.
 *
 * @prop {Object} servicingInfo - The service information object from the API.
 * @prop {Boolean} servicingInfo.multiAgent - Whether the agent is a multi-agent.
 * @prop {Number} servicingInfo.agentID - The ID of the agent.
 * @prop {Number} byBrokerAgentID - The ID of the broker agent.
 *
 * @returns {
 *  agentName: String,
 *  agentId: Number,
 *  agentUrl: Object,
 *  agentInfoProps: Object,
 *  agentTitle: String,
 *  isLoading: Boolean,
 *  modalIsOpen: Boolean,
 *  toggleModal: Function,
 *  contactType: String,
 *  handleContact: Function,
 * }
 */
export const useFeaturedAgent = ({ servicingInfo, byBrokerAgentID }) => {
    const styling = useSelector((state) => state.root.styling);
    const { trackEvent, trackGTM } = useTrackEvent();
    const [modalIsOpen, toggleModal] = useToggle(false);
    const contactType = "Featured Agent";
    const {
        agentID,
        multiAgent,
        agentType: servicingInfoAgentType,
    } = servicingInfo || {};

    const {
        data: agent,
        isLoading,
        isFetching,
        refetch,
    } = useFetchFeaturedAgent({ servicingInfo, byBrokerAgentID });

    const agentInfoProps =
        !isFetching &&
        mapAgentInfo(
            servicingInfoAgentType === "ref" ? servicingInfo : agent,
            styling
        );

    // Should force a refetch if the niche item is multiAgent
    useEffect(() => {
        if (multiAgent) {
            refetch();
        }
    }, [multiAgent, refetch]);

    const handleContact = () => {
        toggleModal();
        trackGTM({
            event: `formContact`,
            action: "click",
            category: "contact",
            type: contactType,
            ...agent?.agentName,
        });
        trackEvent("ACTIONS", {
            CATEGORY: "event",
            ASSOCIATE: "Contact Start",
            ACTION: "click",
            SOURCE_INFO: {
                eventLabel: contactType,
                eventValue: agent?.agentName?.more?.id,
            },
        });
    };
    const agentName = agent?.agentName?.value;
    const {
        uRL,
        stateId,
        nicheItemID: agentNicheItemId,
    } = agent?.agentName?.more || {};
    const agentId = agent?.agentName?.more?.id || agent?.agentID || agentID;
    const agentTitle = agent?.agentTitle_part1
        ? `${agent?.agentTitle_part1?.value} ${agent?.agentTitle_part2?.value}`
        : undefined;
    const agentUrl = uRL
        ? {
              pathname: uRL,
              state: { agentId, stateId, nicheItemId: agentNicheItemId },
          }
        : undefined;
    const agentType = agent?.agentType || servicingInfoAgentType;
    const isRefAgent = agentType === "ref";

    return {
        agentName,
        agentId,
        agentUrl,
        agentInfoProps,
        agentTitle,
        isLoading,
        modalIsOpen,
        toggleModal,
        contactType,
        handleContact,
        agentType,
        isRefAgent,
    };
};

/**
 * This provides the agent information for the agent info component.
 *
 * @param {*} agent
 * @param {*} styling
 * @returns object - The agent information object.
 */
export const mapAgentInfo = (agent, styling) => {
    // If the agent is a LHR agent
    if (agent?.agentName?.more?.id) {
        return {
            agentName: agent?.agentName?.value,
            agentId: agent?.agentName?.more?.id,
            agentType: "lhr",
            imagePath: agent?.agentPic?.imageUrl,
            imageAlt: agent?.agentName?.value,
            phone:
                agent?.agentWebsitePhone?.value ||
                agent?.agentMobilePhone?.value ||
                agent?.agentOfficePhone?.value,
            brokerageName: agent?.brokerageName?.value,
        };
    } else {
        if (styling) {
            const { nicheName, nichePhoneNumber } = styling;
            return {
                agentType: agent?.agentType, // This will either be "ref" of undefined
                imagePath: "/icon-color.png",
                imageAlt: nicheName,
                phone: nichePhoneNumber,
                brokerageName: nicheName,
            };
        }
    }
};
