import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import DateCard from "./DateCard";
import CustomArrow from "./CustomArrow";
import { addDays, formatDate, isSameDay } from "../../../support/helpers";

const ScheduleShowing = ({
    name,
    control,
    numOfDays = 7,
    startDate = addDays(new Date(), 1),
    setValue,
}) => {
    const [selectedDate, setSelectedDate] = useState(startDate);
    const isMobile = useSelector((state) => state.root.isMobile);
    const dates = Array.from({ length: numOfDays }, (_, i) =>
        addDays(startDate, i)
    );
    const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
    const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
    const numOfMobileItems = 2;
    const numOfDesktopItems = 4;

    useEffect(() => {
        setValue(name, formatDate(selectedDate));
    }, [selectedDate, setValue, name]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    <div className="fw-bold mb-2">Select a date</div>
                    <CarouselWrapper isMobile={isMobile}>
                        <Carousel
                            arrows={!isMobile}
                            renderArrowsWhenDisabled={true}
                            customLeftArrow={
                                <CustomArrow
                                    arrowDirection="left"
                                    isDisabled={leftArrowDisabled}
                                />
                            }
                            customRightArrow={
                                <CustomArrow
                                    arrowDirection="right"
                                    isDisabled={rightArrowDisabled}
                                />
                            }
                            draggable
                            swipeable
                            keyBoardControl
                            minimumTouchDrag={80}
                            partialVisible={true}
                            focusOnSelect={false}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 5000, min: 768 },
                                    items: numOfDesktopItems,
                                    partialVisibilityGutter: 10,
                                },
                                mobile: {
                                    breakpoint: { max: 768, min: 0 },
                                    items: numOfMobileItems,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            className="custom-carousel"
                            beforeChange={(currentItem) => {
                                setLeftArrowDisabled(currentItem === 0);
                                setRightArrowDisabled(
                                    currentItem >=
                                        dates.length -
                                            (isMobile
                                                ? numOfMobileItems
                                                : numOfDesktopItems)
                                );
                            }}
                        >
                            {dates.map((date, idx) => (
                                <DateCard
                                    key={idx}
                                    isActive={isSameDay(date, selectedDate)}
                                    onClick={() => {
                                        setSelectedDate(date);
                                        field.onChange(formatDate(date));
                                    }}
                                    date={date}
                                />
                            ))}
                        </Carousel>
                    </CarouselWrapper>
                </>
            )}
        />
    );
};

const CarouselWrapper = styled.div`
    .custom-carousel {
        ${({ isMobile }) =>
            isMobile
                ? "margin: 0 auto; max-width: 100%;"
                : "margin: 0 auto; max-width: 90%;"}
    }
`;

export default ScheduleShowing;
