import React from "react";
import { useSelector } from "react-redux";
import { LuWaves, LuMountain } from "react-icons/lu";
import { useSearchData } from "../../../../../../hooks/useSearchData";
import { getPageType, makePlural } from "../../../../../../support/helpers";
import { useParams } from "react-router-dom";

function NicheItemsTitle({ data }) {
    const isStatePage = getPageType(useParams(), "state");
    const nicheDomain = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );
    const Icon = nicheDomain === "mountainhomesrealty" ? LuMountain : LuWaves;
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const nichetypePlural =
        useSelector((state) => state.root.styling.nichetypePlural) || "niches";
    const { locationData: location, hasSearched } = useSearchData();

    const { nicheItemsPreviewInfo, childNicheItems } = data || {};
    const nicheItems = nicheItemsPreviewInfo || childNicheItems?.dILinks;
    const nicheList =
        hasSearched && location?.matchesNicheItems
            ? (location?.matchesNicheItems?.diNicheItems || []).filter(
                  (item) => !item?.more?.hasChildren
              )
            : nicheItems;

    const titles = {
        lakehomes: makePlural(
            nichetypeSingular,
            nichetypePlural,
            nicheList?.length
        ),
        "beach-homes": makePlural(
            nichetypeSingular,
            nichetypePlural,
            nicheList?.length
        ),
        mountainhomesrealty: `${nichetypeSingular} ${makePlural(
            isStatePage && !hasSearched ? "Region" : "Area",
            isStatePage && !hasSearched ? "Regions" : "Areas",
            nicheList?.length
        )}`,
    };

    return (
        <div className="d-flex align-items-center lh-1">
            <Icon size="1.5rem" />
            <span className="ms-2">
                {nicheList?.length} {titles[nicheDomain] || nichetypePlural}
            </span>
        </div>
    );
}

export default NicheItemsTitle;
