import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import Image from "../Image";
import CdnImage from "../CdnImage";

/**
 * Renders agent information including name, image, phone number, brokerage name, and title.
 * Provides clickable phone number functionality that tracks events on click.
 *
 * @param {string} agentName - The name of the agent.
 * @param {string} agentId - The unique identifier for the agent.
 * @param {string} agentUrl - The URL of the agent's page on the site.
 * @param {string} agentTitle - The title of the agent.
 * @param {string} imageUrl - URL of the agent's image.
 * @param {string} imageAlt - Alternative text for the agent's image.
 * @param {Object} imageProps - Additional props to pass to the Image component.
 * @param {string} phone - The agent's phone number.
 * @param {string} brokerageName - The name of the brokerage the agent is affiliated with.
 * @return {JSX.Element} The rendered agent information component.
 */
const AgentInfo = ({
    className = "d-flex pb-2 border-bottom mb-2",
    agentName,
    agentUrl,
    agentId,
    agentTitle,
    imagePath,
    imageAlt,
    imageProps = { height: "125px" },
    phone,
    brokerageName,
}) => {
    const { trackEvent, trackGTM } = useTrackEvent();

    return (
        <div className={className}>
            <div className="me-2">
                {imagePath &&
                    (agentUrl ? (
                        <Link to={agentUrl}>
                            <CdnImage
                                src={imagePath}
                                alt={imageAlt}
                                test="main-image"
                                {...imageProps}
                            />
                        </Link>
                    ) : (
                        <CdnImage
                            src={imagePath}
                            alt={imageAlt}
                            test="main-image"
                            {...imageProps}
                        />
                    ))}
            </div>
            <div className="agent-info flex-grow-1 me-2">
                {agentName && (
                    <>
                        {agentUrl ? (
                            <Link className="text-truncate" to={agentUrl}>
                                {agentName}
                            </Link>
                        ) : (
                            <div className="h4 mb-0">{agentName}</div>
                        )}
                    </>
                )}
                {agentTitle && <div className="text-muted">{agentTitle}</div>}
                {brokerageName && (
                    <div className="text-muted text-nowrap">
                        {brokerageName}
                    </div>
                )}
                {phone && (
                    <Button
                        className="text-start p-0"
                        color="link"
                        href={`tel:${phone}`}
                        onClick={() => {
                            trackGTM({
                                event: `phoneClick`,
                                action: "click",
                                type: "Agent",
                                category: "contact",
                                button_label: phone,
                                value: phone,
                                agentName,
                            });
                            trackEvent("ACTIONS", {
                                CATEGORY: "phone",
                                ASSOCIATE: "Agent",
                                ACTION: "click",
                                SOURCE_INFO: {
                                    eventValue: agentId,
                                },
                            });
                        }}
                    >
                        {phone}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AgentInfo;
