import React from "react";
import styled from "styled-components";
import { buildQueryParams } from "../../support/helpers";
import { useSelector } from "react-redux";

const StaticMap = ({ mapKey, markers, mapCenter, zoom, asBg, ...props }) => {
    const nichetypePlural = useSelector(
        (state) => state.root.styling.nichetypePlural
    );
    const center = Object.values(mapCenter).join("");
    const query = buildQueryParams({
        key: mapKey,
        center,
        zoom,
        size: props.size || "640x400",
        maptype: props.maptype || "roadmap",
        markers: Object.values(markers)
            .map(
                (marker) =>
                    `${
                        props.markerImage ? `icon:${props.markerImage}%7C` : ""
                    }size:tiny%7C${marker}`
            )
            .join(""),
        scale: asBg ? 2 : 1,
    });
    const source = `https://maps.googleapis.com/maps/api/staticmap${query}`;
    return (
        <div>
            {asBg ? (
                <BackgroundMap className="w-100" source={source} />
            ) : (
                <img
                    src={source}
                    width="100%"
                    alt={`State ${nichetypePlural} Listings`}
                />
            )}
        </div>
    );
};

const BackgroundMap = styled.div`
    height: 400px;
    background: transparent url(${(props) => props.source}) no-repeat center;
    background-size: cover;
`;

export default StaticMap;
