import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import useSupercluster from "use-supercluster";
import MapWrapper from "./MapWrapper";
import { filterMarkers, setSelectedMarker } from "../../reducers/mapReducer";
import {
    averageGeolocation,
    fitBoundsToMarkers,
    fitBoundsToCircle,
    panToPointAndZoom,
    getPageType,
} from "../../support/helpers";
import Marker from "./Marker";
import ClusterMarker from "./ClusterMarker";
import { useMapContext } from "../../contexts/map";
import { useQueryParam, BooleanParam, NumberParam } from "use-query-params";
import { convertNeSwToNwSe } from "google-map-react";
import { useMediaQuery } from "react-responsive";
import { useNacLocation } from "../../hooks/useNacLocation";

/**
 * Map Marker.
 */
const DefaultMarker = ({
    markers: propMarkers,
    markerRadius = "6",
    dynamicMapHeight,
    displayMarkers = true,
    asBg = false,
    circleData = undefined,
    showDynamicOverride = false,
    CustomIcon = undefined,
    size = undefined,
    useCluster: useClusterProp = false,
}) => {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const { state: { agentId } = {} } = useNacLocation();
    const [isMapVisible] = useQueryParam("mapVisible", BooleanParam);
    const [zoomParam] = useQueryParam("mapZoom", NumberParam);
    const isTabletOrSmaller = useMediaQuery({ maxWidth: 992 });
    const {
        state: { map, maps },
    } = useMapContext();
    const firstLoad = useRef(true);
    const stateMarkers = useSelector((state) => state.map.markers);
    const gisDefault = useSelector((state) => state.map.gisDefault);
    const pageType = getPageType(routeParams, false, agentId);
    const zoomsByPageType = {
        state: 6,
        niche: 12,
        offmarket: 12,
        development: 12,
        agent: 11,
        listing: 16,
    };

    const [circle, setCircle] = useState();
    const [bounds, setBounds] = useState([]);
    const [markers, setMarkers] = useState([]);
    const markersDisplayed = useRef();
    const [useCluster, setUseCluster] = useState(useClusterProp);
    const [defaultCenter] = useState(averageGeolocation(markers));
    const isState = markers.length === 1 && markers[0].type === "state";
    const isListing = pageType === "listing";

    useEffect(() => {
        if (!isMapVisible) {
            // if we navigate, we want to
            dispatch(setSelectedMarker());
        }
    }, [isMapVisible, dispatch]);

    useEffect(() => {
        setMarkers(filterMarkers(propMarkers || stateMarkers));
    }, [propMarkers, stateMarkers]);

    useEffect(() => {
        if (!firstLoad.current) {
            setUseCluster(useClusterProp);
        }
    }, [useClusterProp]);

    useEffect(() => {
        if (map && circleData) {
            if (circle) circle.setMap(null);
            if (circleData) {
                const mapCircle = new maps.Circle({
                    strokeColor: "#0077C0",
                    strokeOpacity: 0.8,
                    strokeWeight: 1.5,
                    fillColor: "#000000",
                    fillOpacity: 0.08,
                    map: map,
                    ...circleData,
                });
                // Not sending zoom here so the map will center on the circle.
                fitBoundsToCircle(
                    map,
                    maps,
                    [mapCircle],
                    zoomParam,
                    pageType === "agent" || isListing ? firstLoad.current : true
                );
                setCircle(mapCircle);
            }
        } else if (!circleData && circle) {
            circle.setMap(null);
            setCircle(null);
        }
        // eslint-disable-next-line
    }, [map, maps, circleData]);

    useEffect(() => {
        if (
            map &&
            (!shallowEqual(markers, markersDisplayed.current) ||
                (!markers.length && gisDefault)) &&
            (!isTabletOrSmaller ||
                (isTabletOrSmaller && isMapVisible) ||
                (isTabletOrSmaller && isListing))
        ) {
            // this is in a timeout because on mobile, the map needs to be on the screen
            // or the bounds will be calculated incorrectly.
            const waitTime = isTabletOrSmaller && isMapVisible ? 500 : 0;
            setTimeout(() => {
                const markersForZoom = markers.filter(
                    (marker) => !marker.ignore4zoom
                );
                if (markersForZoom.length > 0) {
                    if (markersForZoom.length > 1) {
                        fitBoundsToMarkers(
                            map,
                            maps,
                            markersForZoom,
                            zoomParam,
                            pageType === "agent" || isListing
                                ? firstLoad.current
                                : true
                        );
                    } else if (markers.length > 0) {
                        // There is only 1 property and we should fix the zoom a bit here.
                        const { latitude, longitude } = markers[0];
                        if (firstLoad.current || isListing) {
                            panToPointAndZoom(
                                map,
                                zoomParam || zoomsByPageType[pageType],
                                new maps.LatLng(latitude, longitude)
                            );
                        }
                    }
                } else if (gisDefault) {
                    const {
                        latitude,
                        longitude,
                        maxLatitude,
                        maxLongitude,
                        minLatitude,
                        minLongitude,
                    } = gisDefault;
                    if (latitude && longitude) {
                        if (
                            maxLatitude &&
                            maxLongitude &&
                            minLatitude &&
                            minLongitude
                        ) {
                            // Setting the Bounds to the min/max GIS points of the lake
                            fitBoundsToMarkers(
                                map,
                                maps,
                                [
                                    {
                                        latitude: maxLatitude,
                                        longitude: maxLongitude,
                                    },
                                    {
                                        latitude: minLatitude,
                                        longitude: minLongitude,
                                    },
                                ],
                                zoomParam,
                                pageType === "agent" || isListing
                                    ? firstLoad.current
                                    : true
                            );
                        } else {
                            // We dont have a way to dynamically figure out the zoom, so it is just centered on the GIS point
                            panToPointAndZoom(
                                map,
                                zoomParam || zoomsByPageType[pageType],
                                new maps.LatLng(latitude, longitude)
                            );
                        }
                    }
                }
                // This needs to be here so that on the intiail load with a zoom parameter
                // the map will pan to the center of markers
                firstLoad.current = false;
                markersDisplayed.current = markers;
            }, waitTime);
        }
        // eslint-disable-next-line
    }, [map, maps, markers, isMapVisible, isTabletOrSmaller, gisDefault]);

    // TODO: figure out how to only pan when you are hovering over listing preview's
    useEffect(() => {
        const selected = markers.find((marker) => marker.selected);
        if (selected && selected.latitude && selected.longitude) {
            map.panTo(new maps.LatLng(selected.latitude, selected.longitude));
        }
        // eslint-disable-next-line
    }, [markers]);

    const points = markers.map((marker) => ({
        type: "Feature",
        properties: {
            cluster: false,
            listingId: marker.listingId || marker.id,
        },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(marker.longitude),
                parseFloat(marker.latitude),
            ],
        },
    }));

    const options = {
        radius: 50,
        maxZoom: 12,
        minPoints: 3,
        // extent: 100,  // this lets you increase/decrease the number of points within the culster
    };

    let adjustedBounds;
    if (map && bounds[0] === 0) {
        const mapBounds = map.getBounds();
        const ne = mapBounds.getNorthEast();
        const sw = mapBounds.getSouthWest();
        const { nw, se } = convertNeSwToNwSe({
            ne: { lat: ne.lat(), lng: ne.lng() },
            sw: { lat: sw.lat(), lng: sw.lng() },
        });
        adjustedBounds = [nw.lng, se.lat, se.lng, nw.lat];
    }

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds: adjustedBounds || bounds,
        zoom: zoomParam || map?.getZoom() || zoomsByPageType[pageType],
        options,
    });

    if (!markers) return null;

    return (
        <MapWrapper
            className="h-100"
            defaultCenter={defaultCenter}
            asBg={asBg}
            showDynamicOverride={showDynamicOverride}
            size={size}
            dynamicMapHeight={dynamicMapHeight}
            setBounds={setBounds}
        >
            {useCluster && clusters && clusters.length > 0
                ? displayMarkers &&
                  clusters &&
                  clusters.map((cluster, i) => {
                      const [longitude, latitude] =
                          cluster.geometry.coordinates;
                      const { cluster: isCluster, listingId } =
                          cluster.properties;

                      if (isCluster) {
                          return (
                              <ClusterMarker
                                  key={`cluster-${cluster.id}`}
                                  lat={latitude}
                                  lng={longitude}
                                  cluster={cluster}
                                  supercluster={supercluster}
                                  map={map}
                                  listingsPreviews={markers}
                              />
                          );
                      }
                      return (
                          <Marker
                              key={`cluster-property-${listingId}-${i}`}
                              lat={latitude}
                              lng={longitude}
                              marker={markers.find(
                                  (fm) =>
                                      fm.listingId === listingId ||
                                      fm.id === listingId
                              )}
                              markerRadius={
                                  cluster.type === "region" ? 15 : markerRadius
                              }
                              CustomIcon={CustomIcon}
                          />
                      );
                  })
                : !isState &&
                  markers &&
                  markers.map((marker, i) => (
                      <Marker
                          key={`property-${marker.listingId || marker.id}-${i}`}
                          lat={marker.latitude}
                          lng={marker.longitude}
                          marker={marker}
                          markerRadius={
                              marker.type === "region" ? 15 : markerRadius
                          }
                          CustomIcon={CustomIcon}
                      />
                  ))}
        </MapWrapper>
    );
};

export default DefaultMarker;
