import { useParams } from "react-router-dom";
import { useInViewport } from "../../../../../hooks/useInViewport";
import { useGetPlacesByCategoryQuery } from "../../../../../services/nicheItemEndpoints";

/**
 * Hook to fetch places by category. This will only fetch if the user agent is not a bot.
 *
 * @param {React.MutableRefObject<HTMLElement | null>} ref - The ref to the element
 * that you want to fetch places for.
 * @param {string} category - The category type.
 * @param {number} latitude - The latitude to fetch places for.
 * @param {number} longitude - The longitude to fetch places for.
 *
 * @returns {Object} - The response object with the places data.
 */
export const useFetchCategory = (ref, category, latitude, longitude) => {
    const userAgentPartsToCheck = ["bot", "crawler", "spider"];
    // Assume that it is a bot until proven otherwise.
    let isBot = true;
    // Check user agent for the word "bot"
    if (
        typeof window !== "undefined" &&
        window !== null &&
        !userAgentPartsToCheck.some((part) =>
            window.navigator.userAgent.toLowerCase().includes(part)
        )
    ) {
        isBot = false;
    }
    const { state, niche: nicheItem } = useParams();
    const isInViewport = useInViewport(ref);
    const response = useGetPlacesByCategoryQuery(
        { state, nicheItem, category, latitude, longitude },
        { skip: !isInViewport || isBot }
    );

    return response;
};
