import React from "react";
import { useSelector } from "react-redux";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import Default from "../../features/Default";
import Adsense from "../../Adsense";
import DisplayAd from "../../CordlessMedia/DisplayAd";
import AdditionalServices from "../../CordlessMedia/AdditionalServices";
import MortgageCalculator from "../../CordlessMedia/MortgageCalculator";
import ContactForm from "../../Contact";
import AgentInfo from "../../AgentInfo";
import Feature from "../../features/Feature";
import { useNacLocation } from "../../../hooks/useNacLocation";
import Telco from "../../CordlessMedia/Telco";
import { mapAgentInfo } from "../../FeaturedAgent/hooks/useFeaturedAgent";
import { useFetchFeaturedAgent } from "../../../hooks/useFetchFeaturedAgent";

/**
 * Bottom Listings page component.
 */
const Bottom = () => {
    const listings = useSelector((state) => state.pages.listings);
    const styling = useSelector((state) => state.root.styling);
    const { state: { listingId = undefined } = {} } = useNacLocation();
    const listing = listings?.[listingId];
    const {
        exterior,
        interior,
        neighborhood,
        financial,
        courtesyOf,
        compensation,
        address,
        identifiers,
        highlights = [],
        byBrokerAgentID,
        servicingInfo,
        isBlockListingAds,
    } = listing || {};
    const price = highlights.find((highlight) => highlight?.id === "price");

    const isArchived = useSelector(
        (state) => state.pages.listings[listingId]?.identifiers?.isArchived
    );
    const isOffmarket = isArchived === 1;
    console.log("isOffmarket", isOffmarket);
    const { agentType } = servicingInfo || {};
    const { data } = useFetchFeaturedAgent({
        servicingInfo,
        byBrokerAgentID,
        forceSkip: isOffmarket,
    });
    const featuredAgent = mapAgentInfo(
        agentType === "ref" ? servicingInfo : data,
        styling
    );
    const { agentName, agentId } = featuredAgent || {};

    return (
        <Fetching waitUntil={listing}>
            <div
                className="container-xxl mt-3 px-3 px-xxl-0"
                data-testid="listing-bottom"
            >
                <div className="row g-0">
                    <div>
                        <Feature
                            title="Exterior Features"
                            show={Array.isArray(exterior) && exterior.length}
                        >
                            <Default data={exterior} />
                        </Feature>
                        <Feature
                            title="Interior Features"
                            show={Array.isArray(interior) && interior.length}
                        >
                            <Default data={interior} />
                        </Feature>
                        <Feature
                            title="Neighborhood & Schools"
                            show={
                                Array.isArray(neighborhood) &&
                                neighborhood.length
                            }
                        >
                            <Default data={neighborhood} />
                        </Feature>
                        <Feature
                            title="Financial Information"
                            id="financial-info"
                            show={true}
                        >
                            <Default data={financial} />
                        </Feature>
                        {!isOffmarket && (
                            <div className="mb-3">
                                <MortgageCalculator
                                    zip={address?.zip}
                                    address={address?.line1}
                                    price={price?.value}
                                />
                            </div>
                        )}
                        <Feature
                            title="Additional Services"
                            show={!isOffmarket}
                            isThirdParty={true}
                            childWrapperClassName=""
                        >
                            <AdditionalServices
                                zipCode={address?.zip}
                                address={address?.line1}
                            />
                        </Feature>
                        <Feature
                            title="Internet Service Providers"
                            show={!isOffmarket}
                            isThirdParty={true}
                            childWrapperClassName=""
                        >
                            <Telco
                                zipCode={address?.zip}
                                address={address?.line1}
                                lat={identifiers?.latitude}
                                long={identifiers?.longitude}
                            />
                        </Feature>
                        <Feature
                            title="Listing Information"
                            show={courtesyOf || compensation}
                        >
                            {courtesyOf && Array.isArray(courtesyOf) && (
                                <DiItems
                                    wrapperClsses="mb-2"
                                    items={courtesyOf}
                                    valueIsHtml
                                />
                            )}
                            {compensation && Array.isArray(compensation) && (
                                <DiItems
                                    wrapperClsses="mb-2 d-flex"
                                    labelClasses="me-2"
                                    valueClasses="fw-bold"
                                    items={compensation}
                                    valueIsHtml
                                />
                            )}
                        </Feature>
                    </div>
                    <div className="contact-section mt-3 p-4 bg-white  col-12 col-lg-7 col-xg-6">
                        <h4 className="pb-3">{`Contact our Agent ${
                            agentName || ""
                        }!`}</h4>
                        {agentId && <AgentInfo {...featuredAgent} />}
                        <ContactForm
                            contactType="MoreInfo"
                            agentId={agentId}
                            agentType={agentType}
                        />
                    </div>
                    {!isBlockListingAds && (
                        <>
                            <DisplayAd location="listing" />
                            <Adsense />
                        </>
                    )}
                </div>
            </div>
        </Fetching>
    );
};

export default Bottom;
