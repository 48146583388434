import React from "react";

/**
 * Contact form disclaimer component.
 * @param {string} brokerageName - The name of the brokerage in question, i.e. 'Lake Homes Realty'.
 * @param {string} buttonText - The text on a submit button, such as 'Submit' or 'Send'.
 * @param {string} domain - The current domain.
 * @returns The disclaimer text used on our contact forms with dynamic button and brokerage names.
 */
const Disclaimer = ({
    brokerageName = "Lake Homes Realty",
    buttonText = "Submit",
    domain = "https://www.lakehomes.com",
}) => {
    return (
        <div className="small text-muted">
            By clicking “{buttonText}”, you agree that {brokerageName}, its
            affiliates, and real estate professionals may contact you by phone,
            text, or email to any phone number and email address you provided,
            including by automated means, even if your number is on a federal,
            state, or our internal Do Not Call list. You also agree to our{" "}
            <a
                className="fw-bolder"
                target="_blank"
                href={`${domain}/info/terms-of-service`}
            >
                Terms of Use
            </a>{" "}
            and{" "}
            <a
                className="fw-bolder"
                target="_blank"
                href={`${domain}/info/privacy-policy`}
            >
                Privacy Policy
            </a>
            .
        </div>
    );
};

export default Disclaimer;
