import React, { useEffect } from "react";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { useSelector } from "react-redux";

/**
 * Google Adsense ins tag. This tag works in conjunction with a javascript snippit that is implemented in
 * google tag manager. This code must be added in the source for the ads to show up properly. The code can
 * be toggled on/off by remove the GTM implementation.
 */
const Adsense = () => {
    const adSlots = { lakehomes: "9013526020", "beach-homes": "8505827709" };
    const { trackGTM } = useTrackEvent();
    const nicheDomain = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );

    const adSlot = adSlots[nicheDomain];
    if (!adSlot) return null;

    useEffect(() => {
        trackGTM({
            event: "adDisplay",
            type: "adsense",
            location: "Listing Page",
        });
    }, []);

    return (
        <div className="my-2" id="adsense">
            <ins
                className="adsbygoogle"
                style={{
                    display: "block",
                    minWidth: "350px",
                    maxWidth: "728px",
                    width: "100%",
                    height: "90px",
                }}
                data-ad-client="ca-pub-6345533651350673"
                data-ad-slot={adSlot}
                // data-ad-format="auto"
                // data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default Adsense;
