import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setMarkers, setGisDefault } from "../../../reducers/mapReducer";
import { useGetNiche } from "../../../hooks/niche";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getPageType } from "../../../support/helpers";
import { setIsFetchingPage, setPageError } from "../../../reducers/rootReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";
import { useSearchData } from "../../../hooks/useSearchData";
import { useNacLocation } from "../../../hooks/useNacLocation";

/**
 * A Development is just a subset of a Niche. The Development component loads its data and then
 * uses the Niche container passing the isDevelopment prop.
 */
const OffmarketProvider = ({ children, isOffmarket }) => {
    const dispatch = useDispatch();
    const offmarket = useSelector((state) => state.pages.offmarket);
    const { resultsData: results } = useSearchData();
    const routeParams = useParams();
    const isOffmarketPage = getPageType(routeParams, "offmarket");
    const isListingPage = getPageType(routeParams, "listing");
    const { state: { stateId, nicheItemId, agentId } = {} } = useNacLocation();
    const { execute: getNiche, error } = useGetNiche();
    const nicheItem = offmarket?.[nicheItemId];
    const { gISDefault } = nicheItem || {};

    useEffect(() => {
        if (
            nicheItem?.pageHeaderInfo &&
            getPageType(routeParams, "offmarket")
        ) {
            dispatch(setPageHeaderInfo(nicheItem?.pageHeaderInfo));
        }
        // eslint-disable-next-line
    }, [nicheItem, dispatch, routeParams]);

    useEffect(() => {
        if (nicheItemId && !nicheItem && isOffmarketPage) {
            const fetchData = async () => {
                dispatch(setIsFetchingPage(true));
                const pageData = {
                    pageType: "nicheItem",
                    ...(await getNiche(
                        stateId,
                        nicheItemId,
                        {},
                        true,
                        agentId
                    )),
                };
                bulkPageReducer(dispatch, pageData);
                dispatch(setIsFetchingPage(false));
            };
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line
    }, [nicheItemId, isOffmarket, isOffmarketPage]);

    useEffect(() => {
        if (
            nicheItemId &&
            isOffmarket &&
            !isListingPage &&
            (results || nicheItem)
        ) {
            const nichListings = results
                ? results?.results
                : nicheItem?.listingsPreview?.results || [];
            if (gISDefault) dispatch(setGisDefault(gISDefault));
            dispatch(
                setMarkers(
                    nichListings.map((listing) => ({
                        ...listing,
                        type: "listing",
                        click: {
                            path: listing.urlPath,
                            state: {
                                listingId: listing.listingId,
                                isOffmarket: true,
                            },
                        },
                    }))
                )
            );
        }
        // eslint-disable-next-line
    }, [nicheItemId, isOffmarket, nicheItem, results]);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "Development",
                    error: "Error loading development page.",
                })
            );
        }
    }, [dispatch, error]);

    return children;
};

export default OffmarketProvider;
