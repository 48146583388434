import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";
import styled from "styled-components";
import InfoWindow from "./InfoWindow";
import { setSelectedMarker } from "../../reducers/mapReducer";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { useQueryParams } from "use-query-params";
import { locationQueryModel, searchQueryModel } from "../Search/SearchProvider";
import { stringify } from "query-string";

// Marker is selected if there is an active marker and the listingId or id equal the active marker
const isSelectedInState = createSelector(
    (state) => state.map.activeMarker,
    (_, rawMarker) => rawMarker,
    (activeMarker, rawMarker) =>
        activeMarker !== undefined &&
        (activeMarker === rawMarker?.listingId ||
            activeMarker === rawMarker?.id)
);

// NOTE -- you must pass lat/lng as props to this component. They are not used in this component but google-maps-react
// requires them for the marker to show up on the map.
const Marker = ({ marker: rawMarker, markerRadius, CustomIcon }) => {
    const dispatch = useDispatch();
    const [locationQuery, setLocationQuery] =
        useQueryParams(locationQueryModel);
    const selectedInState = useSelector((state) =>
        isSelectedInState(state, rawMarker)
    );
    const [searchQuery] = useQueryParams(searchQueryModel);
    const history = useHistory();
    const { trackGTM } = useTrackEvent();
    const [selected, setSelected] = useState(false);
    const flattenMarker = (mk) => ({ ...mk, ...mk?.dIListingPreview });

    if (!rawMarker) return null;
    const marker =
        rawMarker?.type === "listing" ? flattenMarker(rawMarker) : rawMarker;

    const isSelected = selected || selectedInState;

    return (
        <StyledMarker
            className="marker position-relative"
            data-test="map-marker"
            style={{
                zIndex: isSelected ? 1000 : undefined,
                top: `-${markerRadius}px`,
                left: `-${markerRadius}px`,
            }}
            onClick={() => {
                if (marker?.click) {
                    if (isMobile) {
                        dispatch(setSelectedMarker(marker));
                        trackGTM({
                            event: `mobileMarkerClick`,
                            action: "touch",
                            category: "mapInteraction",
                            type: "View Listing Preview",
                        });
                    } else {
                        if (marker.click?.selectRegion) {
                            if (marker.click.selectRegion.valueLink) {
                                const { valueLink, value } =
                                    marker.click.selectRegion;
                                const parts = valueLink.split("/");
                                parts.shift();
                                setLocationQuery({
                                    search: parts[1],
                                    zip: parts[1],
                                    range: parts[2],
                                    region: value,
                                    mapView: undefined,
                                });
                            }
                        } else {
                            const searchParams =
                                rawMarker?.type === "listing"
                                    ? ""
                                    : stringify(searchQuery);
                            history.push(
                                `${marker.click.path}?${searchParams}`,
                                marker.click.state
                            );
                        }
                    }
                }
            }}
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
            offset={markerRadius}
        >
            {CustomIcon ? (
                <CustomIcon size="1.5em" color="#0077C0" />
            ) : (
                <>
                    {!isMobile && (
                        <InfoWindow marker={marker} selected={isSelected} />
                    )}
                    <svg height={markerRadius * 2} width={markerRadius * 2}>
                        <circle
                            cx="50%"
                            cy="50%"
                            r={markerRadius}
                            stroke="white"
                            strokeWidth="2"
                            fill={isSelected ? "#ff0000" : "var(--bs-primary)"}
                        />
                    </svg>
                </>
            )}
        </StyledMarker>
    );
};

const StyledMarker = styled.div`
    cursor: pointer;
    .info {
        white-space: nowrap;
        left: 50%;
        bottom: ${(props) => props.offset * 2}px;
        margin-left: ${(props) => props.offset}px;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        &.listing {
            min-width: 120px;
            white-space: inherit;
        }
    }
`;

export default Marker;
