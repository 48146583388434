import React from "react";
import styled from "styled-components";

/**
 * A component to display a date in a readable format.
 *
 * @param {Date} date - The date to be displayed.
 * @param {boolean} isActive - Whether the date is active (i.e. highlighted).
 * @param {string} className - Additional classes to apply to the component.
 * @param {Object} props - Additional props to pass to the component.
 *
 * @returns {React.ReactElement} A React element representing the date in a readable format.
 */
const DateCard = ({
    date,
    isActive,
    className = "py-2 rounded text-center mx-1",
    ...props
}) => {
    const dayOfWeek = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
    }).format(date);
    const monthDay = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
    }).format(date);

    return (
        <StyledDateCard
            className={`${isActive && "active"} ${className}`}
            {...props}
        >
            <div>{dayOfWeek}</div>
            <div className="fw-bold">{monthDay}</div>
        </StyledDateCard>
    );
};

const StyledDateCard = styled.div`
    border: 1px solid var(--bs-light-gray);
    cursor: pointer;
    &:hover {
        background-color: var(--bs-gray-200);
    }
    &.active {
        border-color: var(--bs-tertiary);
        background-color: color-mix(
            in srgb,
            var(--bs-primary-light) 20%,
            transparent
        );
        color: var(--bs-tertiary);
    }
`;

export default DateCard;
