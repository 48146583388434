import React, { useEffect } from "react";
import Drawer from "../Drawer";
import AgentInfo from "../AgentInfo";
import Contact from "../Contact";
import Loading from "../Loading";
import { Button, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useFeaturedAgent } from "./hooks/useFeaturedAgent";
import styled from "styled-components";

/**
 * A component that renders a featured agent with a name, phone number, and image,
 * as well as a button to view the agent's listings and a contact form.
 *
 * @param {object} props - The props to pass to the hook
 * @param {string} className - The class name for the component
 * @param {string} dataTest - The test attribute for the component
 * @param {boolean} showAgentTitle - Whether or not to show the agent's title
 * @returns {JSX.Element} The rendered component
 */
function FeaturedAgent({
    className,
    dataTest = "featured-agent",
    showAgentTitle = true,
    ...props
}) {
    const {
        agentName,
        agentId,
        agentUrl,
        agentInfoProps,
        agentTitle,
        isLoading,
        modalIsOpen,
        toggleModal,
        contactType,
        handleContact,
        agentType,
        isRefAgent,
    } = useFeaturedAgent(props);

    return (
        <>
            {isLoading && <Loading />}
            {!isLoading && (
                <div className={className} data-test={dataTest}>
                    {showAgentTitle && agentTitle && (
                        <div className="text-muted">{agentTitle}</div>
                    )}
                    <div className="d-flex align-items-center">
                        <AgentInfoWrapper
                            className="d-flex align-items-center"
                            {...agentInfoProps}
                            agentUrl={agentUrl}
                            imageProps={{
                                height: "55px",
                            }}
                        />
                        <div className="d-flex align-content-center ms-auto">
                            <div>
                                {agentUrl && (
                                    <Link
                                        className="btn btn-primary inverse px-1 py-1 w-100 mb-1 text-nowrap"
                                        to={agentUrl}
                                    >
                                        View My Listings
                                    </Link>
                                )}
                                <Button
                                    className={`inverse py-1 w-100 ${
                                        isRefAgent ? "px-3" : "px-1"
                                    }`}
                                    color="primary"
                                    onClick={() => handleContact()}
                                >
                                    Contact Agent
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Drawer
                        isOpen={modalIsOpen}
                        toggle={() => toggleModal()}
                        headerText={`Contact ${agentName || "Agent"}`}
                    >
                        <ModalBody>
                            <AgentInfo {...agentInfoProps} />
                            <Contact
                                contactType={contactType}
                                agentId={agentId}
                                agentType={agentType}
                            />
                        </ModalBody>
                    </Drawer>
                </div>
            )}
        </>
    );
}

const AgentInfoWrapper = styled(AgentInfo)`
    .agent-info > * {
        line-height: 1.2;
    }
`;

export default FeaturedAgent;
