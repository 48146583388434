import React from "react";
import { Button, ModalBody } from "reactstrap";
import Drawer from "../../../Drawer";
import AgentInfo from "../../../AgentInfo";
import ContactForm from "../../../Contact";
import useToggle from "../../../../hooks/useToggle";
import { useTrackEvent } from "../../../../hooks/useTrackEvent";
import { useNacLocation } from "../../../../hooks/useNacLocation";

const Contact = ({
    children,
    contactType = "MoreInfo",
    header = "Request More Info",
    featuredAgent,
    ...props
}) => {
    const [modalIsOpen, toggleModal] = useToggle(props?.modalIsOpen || false);
    const { trackEvent, trackGTM } = useTrackEvent();
    const {
        state: { nicheItemId, listingId },
    } = useNacLocation();
    const { agentName, agentId, agentType } = featuredAgent || {};

    return (
        <>
            <Button
                {...props}
                onClick={() => {
                    if (props.onClick) props.onClick();
                    toggleModal();
                    trackGTM({
                        event: `formContact`,
                        action: "click",
                        type: contactType,
                        category: "contact",
                        button_label: children[1] || undefined,
                        featuredAgent: agentName,
                        listingId,
                        nicheItemId,
                    });
                    trackEvent("ACTIONS", {
                        CATEGORY: "event",
                        ASSOCIATE: "Contact Start",
                        ACTION: "click",
                        SOURCE_INFO: {
                            eventLabel: contactType,
                            eventValue: agentId || listingId || nicheItemId,
                        },
                    });
                }}
            >
                {children}
            </Button>
            <Drawer
                isOpen={modalIsOpen}
                toggle={() => toggleModal()}
                headerText={header}
            >
                <ModalBody>
                    {agentId && <AgentInfo {...featuredAgent} />}
                    <ContactForm
                        contactType={contactType}
                        agentId={agentId}
                        agentType={agentType}
                        closeModal={() => toggleModal(false)}
                    />
                </ModalBody>
            </Drawer>
        </>
    );
};

export default Contact;
