import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetNiche } from "../../../hooks/niche";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getPageType } from "../../../support/helpers";
import { setIsFetchingPage, setPageError } from "../../../reducers/rootReducer";
import { setMarkers, setGisDefault } from "../../../reducers/mapReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";

const NicheItemProvider = ({ children, isOffmarket }) => {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const [mapView] = useQueryParam("mapView", StringParam);
    const nicheItems = useSelector((state) => state.pages.nicheItems);
    const offmarket = useSelector((state) => state.pages.offmarket);
    const { gisData: gis } = useSearchData();
    const isNichePage =
        getPageType(routeParams, "niche") ||
        getPageType(routeParams, "offmarket");
    const { state: { stateId, nicheItemId, agentId } = {} } = useNacLocation();
    const { execute: getNiche, error } = useGetNiche();

    const nicheItem = isOffmarket
        ? offmarket?.[nicheItemId]
        : nicheItems?.[nicheItemId];
    const { childNicheItems, gISDefault } = nicheItem || {};

    const isListingsView =
        mapView === "listings" || (childNicheItems && !mapView);

    useEffect(() => {
        if (nicheItem?.pageHeaderInfo && getPageType(routeParams, "niche")) {
            dispatch(setPageHeaderInfo(nicheItem?.pageHeaderInfo));
        }
    }, [nicheItem, dispatch, routeParams]);

    useEffect(() => {
        if (nicheItemId && !nicheItem && isNichePage && !isOffmarket) {
            const fetchData = async () => {
                dispatch(setIsFetchingPage(true));
                const pageData = {
                    pageType: "nicheItem",
                    ...(await getNiche(
                        stateId,
                        nicheItemId,
                        { gispreview: true },
                        isOffmarket,
                        agentId
                    )),
                };
                bulkPageReducer(dispatch, pageData);
                dispatch(setIsFetchingPage(false));
            };
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line
    }, [nicheItemId, isOffmarket, isNichePage]);

    useEffect(() => {
        if (nicheItemId && isNichePage && !isOffmarket) {
            if (gISDefault) dispatch(setGisDefault(gISDefault));
            if (
                childNicheItems &&
                !isListingsView &&
                childNicheItems?.dILinks.length > 0
            ) {
                dispatch(
                    setMarkers(
                        childNicheItems.dILinks.map((item) => ({
                            ...item,
                            ...item.more,
                            type: "parent",
                            click: {
                                path: item.valueLink,
                                state: { nicheItemId: item.more.nicheItemID },
                            },
                        }))
                    )
                );
            } else if (gis || nicheItem) {
                const nichListings = gis
                    ? gis?.results || []
                    : nicheItem?.gis?.results || [];
                if (Array.isArray(nichListings) && nichListings.length > 0) {
                    dispatch(
                        setMarkers(
                            nichListings.map((listing) => ({
                                ...listing,
                                type: "listing",
                                click: {
                                    path: listing.urlPath,
                                    state: { listingId: listing.listingId },
                                },
                            }))
                        )
                    );
                } else {
                    dispatch(setMarkers([]));
                }
            } else {
                dispatch(setMarkers([]));
            }
        }
        // eslint-disable-next-line
    }, [nicheItemId, isOffmarket, gis, isListingsView, nicheItem, isNichePage]);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "Niche",
                    error: "Error loading niche page.",
                })
            );
        }
    }, [dispatch, error]);

    return children;
};

export default NicheItemProvider;
