import React from "react";
import PagnatedListing from "../../../PagnatedListing";
import { Accordion, AccordionBody, AccordionItem } from "reactstrap";
import AccordionNicheItems from "../../State/StateAccordion/NicheItems";
import StateAccordionHeader from "../../State/StateAccordion/StateAccordionHeader";
import ListingsTitle from "../../State/StateAccordion/Listings/ListingsTitle";
import { useNicheAreasAndListings } from "./hooks/useNicheAreasAndListings";

const NicheAreasAndListings = ({ isOffmarket }) => {
    const {
        open,
        toggle,
        listingsPreview,
        urlPath,
        nicheData,
        nicheList,
        childNicheItems,
    } = useNicheAreasAndListings();

    return (
        <>
            {childNicheItems ? (
                <Accordion
                    open={open}
                    toggle={toggle}
                    className="rotate-ninety"
                >
                    <AccordionNicheItems
                        id="areas"
                        data={nicheData}
                        isOpen={open === "areas"}
                        nicheListOverride={nicheList}
                    />

                    <AccordionItem>
                        <StateAccordionHeader
                            targetId="listings"
                            isOpen={open === "listings"}
                        >
                            <ListingsTitle initialData={listingsPreview} />
                        </StateAccordionHeader>
                        <AccordionBody accordionId="listings">
                            <PagnatedListing
                                title=""
                                paginationUrl={urlPath}
                                initialData={listingsPreview}
                                listingItemClasses="col-12 col-sm-6"
                                isOffmarket={isOffmarket}
                                hideSortOrder={isOffmarket}
                            />
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            ) : (
                <PagnatedListing
                    title=""
                    paginationUrl={urlPath}
                    initialData={listingsPreview}
                    listingItemClasses="col-12 col-sm-6"
                    isOffmarket={isOffmarket}
                    hideSortOrder={isOffmarket}
                />
            )}
        </>
    );
};

export default NicheAreasAndListings;
